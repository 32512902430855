import React, { Component, Fragment } from 'react';
import RequestDemoForm from '../RequestDemoForm';
import Link from '../../../../../core/Link';
import VideoCta from '../../../../../ui-library/VideoCta';
import SingleTestimonial from '../../../../../ui-library/Testimonials/SingleTestimonial';
import trailsideInn from '../../../../../../images/trailside-josh-and-ann.jpeg';

import './index.css';
import StackedCTA from '../../../../../ui-library/StackedCTA';
import Vimeo from '@u-wave/react-vimeo';
import useMedia from '../../../../../../utils/useMedia';

export const RequestDemoPanel = () => {
  const ctaTitle = useMedia(
    ['(min-width: 1025px)'],
    ['See ThinkReservations in Action'],
    ''
  );
  return (
    <>
      <div className="component request-demo-panel text-left -mb-16">
        <div className="grid lg:gap-16 lg:grid-cols-2 max-w-7xl mx-auto">
          <h1 className="mt-1 block text-4xl tracking-tight font-extrabold block text-gray-900 mb-4 lg:hidden">
            See ThinkReservations in Action
          </h1>
          <div className="marketing-content order-2 mt-4 lg:mt-0 lg:order-1">
            <StackedCTA
              headline={ctaTitle}
              copy={`You'll notice the difference when you meet our staff. We want to make sure that ThinkReservations is the right product for your business and we have the knowledge and experience to provide that guidance. Join us for a free demo and, in under an hour, you'll experience how ThinkReservations can streamline your business, saving you and your team time, and helping you make more money.`}
              children={
                <p className="font-bold text-gray-700 text-md">
                  Submit this form and check your email to schedule a personalized demo!
                </p>
              }
              // mediaLeft={true}
              Media={() => (
                <>
                  <span className="sr-only">Watch our video to learn more</span>
                  <Vimeo responsive video={'588883373'} />
                </>
              )}
            />
          </div>
          <div className="form lg:order-2 text-left">
            <RequestDemoForm />
          </div>
        </div>
      </div>
      <SingleTestimonial
        quote={`We moved over to ThinkReservations years ago and haven't been happier. The other systems we tried were watered down. ThinkReservations is for an engaged owner.`}
        author="Joshua + Ann Eckler"
        company={'Trailside Inn, Killington, VT'}
        Image={() => (
          <img
            className="object-cover object-[50%_33%] lg:h-full lg:w-full"
            src={trailsideInn}
            alt=""
          />
        )}
      />
      <div className="pb-24 bg-[#f5f3f3]"></div>
    </>
  );
};

export default RequestDemoPanel;
