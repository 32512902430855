import React, { Component } from 'react';

import './index.css';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  company: '',
  website: '',
  email: '',
  phoneNumber: '',
  numberOfUnits: '',
  howDidYouHearAboutThinkReservations: '',
  touchedFieldNames: [],
  isSubmitting: false,
  isEmailInvalid: false,
};

const validateEmail = (email) => {
  const r = new RegExp(
    "^[-a-zA-Z0-9.'â€™&_%+]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,20}$"
  );
  return r.test(email);
};

export class RequestDemoForm extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  render() {
    const { isSubmitting } = this.state;

    return (
      <div className="component request-demo-form">
        <form
          name="RequestDemoForm"
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D3t000005usVb"
          method="POST"
          onSubmit={this.handleOnSubmit}
        >
          <input type="hidden" name="oid" value="00D3t000005usVb" />
          <input
            type="hidden"
            name="retURL"
            value="https://www.thinkreservations.com/request-a-demo-confirmation"
          />

          <div className="form-field-container name">
            {this.renderTextField(
              'firstName',
              'first_name',
              'first_name',
              'First name'
            )}
            {this.renderTextField(
              'lastName',
              'last_name',
              'last_name',
              'Last name'
            )}
          </div>
          {this.renderTextField(
            'company',
            'company',
            'company',
            'Property name'
          )}
          <div className="form-field-container name">
            {this.renderTextField(
              'numberOfUnits',
              '00N3t00000HzMbz',
              '00N3t00000HzMbz',
              'Number of units'
            )}
            {this.renderTextField(
              'phoneNumber',
              'phone',
              'phone',
              'Phone number'
            )}
          </div>
          {this.renderTextField('website', 'url', 'url', 'Website')}
          {this.renderTextField('email', 'email', 'email', 'Email')}

          <div className="form-field">
            <div className="form-field-label">
              <label htmlFor="00N3t00000HzMbV">
                How did you hear about ThinkReservations?
              </label>
            </div>
            <select
              id="00N3t00000HzMbV"
              name="00N3t00000HzMbV"
              title="How did you hear about us?"
            >
              <option value="">--None--</option>
              <option value="Customer Referral">Customer Referral</option>
              <option value="Google Search">Google Search</option>
              <option value="Review Site">Review Site</option>
              <option value="Conference">Conference</option>
              <option value="Integration Partner">Integration Partner</option>
              <option value="Facebook">Facebook</option>
              <option value="Mail">Mail</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <button
            className={`submit ${isSubmitting ? 'is-submitting' : ''}`}
            type="submit"
          >
            {isSubmitting ? 'Signing up...' : 'Sign up for a demo'}
          </button>
        </form>
      </div>
    );
  }

  renderTextField(stateKey, id, name, label) {
    const value = this.state[stateKey];
    const isTouched = this.state['touchedFieldNames'].includes(stateKey);
    const isEmailInvalid = this.state['isEmailInvalid'];

    const isRequired = isTouched && value.length === 0;

    const hasError = isRequired;
    return (
      <div className={`form-field ${hasError ? 'error' : ''}`}>
        <div className="form-field-label">
          <label htmlFor={id}>{label}</label>
          {isTouched && value.length === 0 && (
            <span className="error"> is required</span>
          )}
          {stateKey === 'email' && value.length > 0 && isEmailInvalid && (
            <span className="error"> is invalid</span>
          )}
        </div>
        <input
          id={id}
          name={name}
          type="text"
          value={value}
          onChange={this.handleTextOnChange.bind(this, stateKey)}
          onBlur={this.handleTextOnBlur.bind(this, stateKey)}
        />
      </div>
    );
  }

  handleOnSubmit(e) {
    const {
      firstName,
      lastName,
      company,
      website,
      email,
      phoneNumber,
      numberOfUnits,
    } = this.state;

    this.setState({
      touchedFieldNames: [
        'firstName',
        'lastName',
        'company',
        'website',
        'email',
        'phoneNumber',
        'numberOfUnits',
      ],
    });

    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      company.length === 0 ||
      website.length === 0 ||
      email.length === 0 ||
      phoneNumber.length === 0 ||
      numberOfUnits.length === 0
    ) {
      // We are missing some fields.
      e.preventDefault();
    } else if (this.state['isEmailInvalid'] === true) {
      // We have an invalid email
      e.preventDefault();
    } else {
      // We can submit the form!
      this.setState({ isSubmitting: true });
    }
  }

  handleTextOnChange(stateKey, e) {
    const value = e.target.value;
    const newIsEmailInvalid =
      stateKey === 'email' ? false : this.state['isEmailInvalid'];

    this.setState({
      [stateKey]: value,
      isEmailInvalid: newIsEmailInvalid,
    });
  }

  handleTextOnBlur(stateKey) {
    const touchedFieldNames = this.state['touchedFieldNames'];
    if (touchedFieldNames.includes(stateKey) === false) {
      const newTouchedFieldNames = [...touchedFieldNames, stateKey];

      this.setState({ touchedFieldNames: newTouchedFieldNames });
    }

    if (stateKey === 'email') {
      const value = this.state[stateKey];
      if (value.length > 0) {
        const isEmailValid = validateEmail(value);
        this.setState({ isEmailInvalid: !isEmailValid });
      } else {
        this.setState({ isEmailInvalid: false });
      }
    }
  }
}

export default RequestDemoForm;
